<template>
  <v-row>
    <v-col md="6" cols="12">
      <v-card class="border-radius-xl card-shadow" @click="_showStudents">
        <div class="px-6 py-6 text-center">
          <h1 class="text-gradient text-success font-weight-bolder text-h1">
            <span>{{ totalStudents }}</span>
          </h1>
          <h6 class="mb-0 text-h6 font-weight-bolder text-typo">Нийт Сурагч</h6>
          <p class="opacity-8 mb-0 text-sm text-typo"></p>
        </div>
      </v-card>
    </v-col>
    <v-col md="6" cols="12">
      <v-card class="border-radius-xl card-shadow">
        <div class="px-6 py-6 text-center">
          <h1 class="text-gradient text-success font-weight-bolder text-h1">
            <span>{{ totalTeachers }}</span>
          </h1>
          <h6 class="mb-0 text-h6 font-weight-bolder text-typo">Нийт Багш</h6>
          <p class="opacity-8 mb-0 text-sm text-typo"></p>
        </div>
      </v-card>
    </v-col>
    <v-dialog v-model="showStudentDialog">
      <v-card height="100%">
        <p
          class="py-0 px-4"
          v-for="(school, schoolIndex) in schools"
          :key="'school' + schoolIndex"
        >
          {{ school.index }}. {{ school.name }} -
          <span v-if="school.students">{{ school.students.length }}</span>
        </p>

        <h1 v-if="schools">{{ schools.length }}</h1>
        <p
          v-for="(student, sindex) in students"
          :key="sindex"
          class="py-0 px-4"
        >
          {{ student.index }}. {{ student.firstName }}
        </p>
      </v-card>
    </v-dialog>
  </v-row>
  <!-- <v-row>
      <v-col md="6" cols="12">
        <v-card class="border-radius-xl card-shadow">
          <div class="px-6 py-6 text-center">
            <h1 class="text-gradient text-success font-weight-bolder text-h1">
              <span>0</span>
              <span class="text-lg">ц</span>
            </h1>
            <h6 class="mb-0 text-h6 font-weight-bolder text-typo">Xоцролт</h6>
            <p class="opacity-8 mb-0 text-sm text-typo"></p>
          </div>
        </v-card>
      </v-col>
      <v-col md="6" cols="12">
        <v-card class="border-radius-xl card-shadow">
          <div class="px-6 py-6 text-center">
            <h1 class="text-gradient text-success font-weight-bolder text-h1">
              <span>0</span>
              <span class="text-lg ms-1">ц</span>
            </h1>
            <h6 class="mb-0 text-h6 font-weight-bolder text-typo">Тасалсан</h6>
            <p class="opacity-8 mb-0 text-sm text-typo"></p>
          </div>
        </v-card>
      </v-col>
    </v-row> -->
  <!-- <div class="px-4 py-4">
      <h6 class="text-h6 text-typo font-weight-bold">
        Суралцагчдын тоо {{ $attrs.sorilId }},
        <span v-if="$attrs.lessonCategories">{{
          $attrs.lessonCategories.length
        }}</span>
      </h6>
      <div class="chart pt-4">
        <canvas :id="chartConsWeek" class="chart-canvas" height="170"></canvas>
      </div>
    </div> -->
</template>
<script>
/*eslint-disable*/
import Chart from "chart.js/auto";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data() {
    return {
      chartConsWeek: "chart-cons-week",
      // schools: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII"],
      // xLabels: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII","IX","X"],
      yData: [20, 100, 80, 52, 68, 90, 70, 90],
      school: null,
      showStudentDialog: false,
      students: null,
    };
  },
  props: {
    totalStudents: {
      type: Number,
      default: 0,
    },
    totalTeachers: {
      type: Number,
      default: 0,
    },
    schools: {
      type: Array,
    },
  },
  computed: {
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
  },
  created() {},
  methods: {
    _showStudents() {
      this.showStudentDialog = true;
    },
  },

  mounted() {
    var el = document.getElementById(this.chartConsWeek);
    if (el) {
      new Chart(el.getContext("2d"), {
        type: "bar",
        data: {
          labels: this.$attrs.lessonCategories,
          datasets: [
            {
              label: "Watts",
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
              backgroundColor: "#3A416F",
              data: this.yData,
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
          },
        },
      });
    }
  },
};
</script>
