<template>
  <DrawerEyesh v-if="isEyesh" :drawer="drawer" :sidebarColor="sidebarColor" :sidebarTheme="sidebarTheme"></DrawerEyesh>
  <DrawerStandard v-else :drawer="drawer" :sidebarColor="sidebarColor" :sidebarTheme="sidebarTheme"></DrawerStandard>
</template>
<script>
import { sync } from "vuex-pathify";
import { EYESH_APP } from "../firebaseConfig";
import DrawerStandard from "./Drawer1.vue";
import DrawerEyesh from "./Drawer2.vue";
export default {
  name: "ddd",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "transparent",
    },
  },
  components: {
    DrawerStandard,
    DrawerEyesh,
  },
  data: () => ({}),
  computed: {
    ...sync("*"),
    isEyesh() {
      return EYESH_APP;
    },
  },
};
</script>
