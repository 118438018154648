<template>
  <v-card>
    <div class="px-4 py-4">
      <h6 class="text-h6 text-typo font-weight-bold">Сурагчдын тоо (ангиар)</h6>

      <div class="card-padding">
        <div class="chart">
          <canvas id="departmentChart" class="chart-canvas"></canvas>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
/*eslint-disable*/
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
Chart.register(ChartDataLabels);
export default {
  components: {},
  data() {
    return {
      chartLabels: null,
      chartNumbers: null,
      yData: [20, 100, 80, 52, 68, 90, 70, 90],
      schools2: null,
      school: null,
    };
  },
  computed: {
    ...sync("*"),
  },
  methods: {
    find() {
      setTimeout(() => {
        var xChart = document.getElementById("departmentChart");
        if (xChart) {
          new Chart(xChart.getContext("2d"), {
            type: "bar",
            data: {
              labels: this.chartLabels,
              datasets: [
                // {
                //   label: "Watts",
                //   tension: 0.4,
                //   borderWidth: 0,
                //   borderRadius: 4,
                //   borderSkipped: false,
                //   backgroundColor: "#3A416F",
                //   data: this.averageScores,
                //   maxBarThickness: 6,
                //   datalabels: {
                //     color: "green",
                //     align: "end",
                //     anchor: "end",
                //   },
                // },
                {
                  label: "Сонгосон тоо",
                  tension: 0.4,
                  borderWidth: 0,
                  borderRadius: 4,
                  borderSkipped: false,
                  backgroundColor: "#ff5a5f",
                  data: this.chartNumbers,
                  maxBarThickness: 6,
                  datalabels: {
                    color: "#ff5a5f",
                    align: "end",
                    anchor: "end",
                  },
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  color: "#36A2EB",
                },
              },
              interaction: {
                intersect: true,
                mode: "index",
              },
              scales: {
                y: {
                  grid: {
                    drawBorder: false,
                    display: false,
                    drawOnChartArea: true,
                    drawTicks: false,
                    borderDash: [5, 5],
                  },
                  ticks: {
                    display: true,
                    padding: 15,
                    color: "#9ca2b7",
                  },
                },
                x: {
                  grid: {
                    drawBorder: false,
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: false,
                    borderDash: [5, 5],
                  },
                  ticks: {
                    display: true,
                    padding: 10,
                    color: "#9ca2b7",
                  },
                },
              },
            },
          });
        }
      }, 1000);
    },
  },
  // props: {
  //   departments: {
  //     required: true,
  //     type: Array,
  //   },
  // },
  mounted() {
    if (this.$store.state.userData.school)
      this.$store.state.userData.school.ref
        .collection("departments")
        .where("startYear", "==", 2022)//YTODO
        .orderBy("index", "desc")
        .get()
        .then((departments) => {
          this.chartLabels = [];
          this.chartNumbers = [];
          departments.forEach((dep) => {
            let department = dep.data();
            department.ref = dep.ref;
            department.id = dep.id;
            if (!department.numberOfStudents)
              this.userData.school.ref
                .collection("students")
                .where(
                  "department-" + this.userData.school.currentYear,
                  "==",
                  department.ref
                )
                .get()
                .then((student) => {
                  department.ref.update({ numberOfStudents: student.size });
                  department.numberOfStudents = student.size;
                });
            this.chartLabels.push(department.name);
            this.chartNumbers.push(department.numberOfStudents);
          });
          this.find();
        });
  },
};
</script>
