<template>
  <v-card class="card-shadow border-radius-xl mt-6" id="salary" v-if="xitems">
    <div class="px-6 py-6 d-flex">
      <h5 class="text-h5 font-weight-bold text-typo">{{ title }}</h5>
      <v-btn
        elevation="0"
        small
        :ripple="false"
        height="21"
        class="
          border-radius-md
          font-weight-bolder
          px-3
          py-3
          badge-font-size
          ms-auto
          text-success
        "
        color="#cdf59b"
        @click="_addEducation"
        >+</v-btn
      >
    </div>
    <v-data-table :headers="headers" :items="xitems" :items-per-page="-1">
      <template slot="item" slot-scope="props">
        <tr>
          <td>{{ props.item.index }}</td>
          <td>
            {{ props.item.salaryAmount }}
          </td>
          <td>{{ props.item.issuedDate }}</td>

          <td>
            <template>
              <v-icon small class="mr-2" @click.stop="_delete(props.item)">
                mdi-delete
              </v-icon>
              <v-icon
                small
                class="mr-2"
                @click.stop="_edit(props.item, props.item.index)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="addEducationDialog" max-width="600px" max-height="800px">
      <v-card class="pb-4">
        <v-card-title>
          <span class="headline">Цалин олголт</span>
        </v-card-title>
        <v-card-text>
          <!-- <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field v-model="xitem.name" label="Нэр" />
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-menu
                ref="menuRef"
                v-model="menuOpen"
                :close-on-content-click="false"
                :return-value.sync="xitem.issuedDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="xitem.issuedDate"
                    label="Picker in menu"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="xitem.issuedDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuOpen = false">
                    Цуцлаx
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuRef.save(xitem.issuedDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6">
              <v-text-field
                type="number"
                v-model.number="xitem.salaryAmount"
                label="Тоо xэмжээ"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-textarea v-model="xitem.description" label="Тэмдэглэгээ" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-10">
          <v-btn
            :ripple="false"
            elevation="0"
            dark
            class="
              font-weight-bolder
              btn-danger
              bg-gradient-danger
              py-5
              px-6
              ms-2
            "
            small
            @click="_deleteEducation"
          >
            Устгаx
          </v-btn>
          <v-spacer />
          <v-btn color="blue" text @click="_closeEducationDialog">
            Цуцлаx
          </v-btn>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              ms-auto
              btn-primary
              bg-gradient-primary
              py-3
              px-6
              ms-3
            "
            @click="_saveEducationDialog"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    dateMenu: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuOpen: false,

    collectionPath: "salaries",
    xitems: null,
    addEducationDialog: false,
    editedEducationIndex: -1,
    xitem: {
      name: null,
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
          width: "1%",
        },

        {
          text: "Цалин",
          align: "start",
          sortable: true,
          value: "salaryAmount",
        },
        {
          text: "Огноо",
          align: "start",
          sortable: true,
          value: "issuedDate",
        },
      ];
    },
  },
  props: {
    // idd: {
    //   required: true,
    // },
    title: {
      required: true,
    },
    user: {
      required: true,
    },
  },
  methods: {
    _addEducation() {
      this.addEducationDialog = true;
    },
    _saveEducationDialog() {
      this.xitem.modifiedByRef = this.$store.state.userData.ref;
      this.xitem.modifiedByEmail = this.$store.state.userData.email;

      if (this.editedEducationIndex == -1) {
        this.user.ref.collection(this.collectionPath).doc().set(this.xitem);
      } else {
        this.xitem.ref.update(this.xitem);
      }

      this.xitem = {};
      this._close();
    },
    _closeEducationDialog() {
      this.addEducationDialog = false;
      this.editedEducationIndex = -1;
    },
    _deleteEducation() {
      this.xitem.ref.delete().then(() => {
        this.xitem = {};
        this._close();
      });
    },
    _delete(item) {
      var yes = confirm("Та үүнийг устгаxдаа итгэлтэй байна уу?");
      if (yes) {
        item.ref.delete().then(() => {
          this._close();
        });
      }
    },
    _edit(educa, index) {
      this.addEducationDialog = true;
      this.xitem = educa;
      this.editedEducationIndex = index;
    },
    _close() {
      this.addEducationDialog = false;
      this.editedEducationIndex = -1;
    },
  },
  created() {
    this.user.ref
      .collection(this.collectionPath)
      .onSnapshot((querySnapshot) => {
        this.xitems = [];
        var counter = 0;
        querySnapshot.forEach((doc) => {
          counter++;
          let eduData = doc.data();
          eduData.id = doc.id;
          eduData.ref = doc.ref;
          eduData.index = counter;
          this.xitems.push(eduData);
        });
      });
  },
};
</script>
