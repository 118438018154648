<template>
  <v-card class="">
    <div class="px-4 py-4">
      <h6 class="text-h6 text-typo font-weight-bold">Сурлагын түвшин</h6>
      <div class="chart pt-4">
        <canvas id="chart-cons-week" class="chart-canvas" height="170"></canvas>
      </div>
    </div>
  </v-card>
</template>
<script>
/*eslint-disable*/
import Chart from "chart.js/auto";
export default {
  components: {},
  data() {
    return {
      chartConsWeek: "chart-cons-week",
    };
  },
  mounted() {
    new Chart(document.getElementById(this.chartConsWeek).getContext("2d"), {
      type: "bar",
      data: {
        labels: ["I", "II", "III", "IV", "V", "VI", "VII","VIII"],
        datasets: [
          {
            label: "Watts",
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "#3A416F",
            data: [20, 100, 80, 52, 68, 90, 70,90],
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
        },
      },
    });
  },
};
</script>
