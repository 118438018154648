<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col md="8" class="me-auto text-start">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">Дүн шилжилгээ</h5>
        <p class="text-sm text-body mb-0">
          Xяналтын самбарын тусламжтай өгөгдөл шинжилгээг xийж сургуулийн үйл
          ажиллагаагаа xянаx боломжтой.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="4" md="6" cols="12">
        <v-card class="card-shadow border-radius-xl" @click="showBasicAlert">
          <div class="px-4 py-4">
            <div class="chart">
              <ScoreWidget></ScoreWidget>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col lg="4" md="6" cols="12">
        <v-card class="card-shadow border-radius-xl" @click="showBasicAlert">
          <div class="px-4 py-4">
            <div class="chart">
              <doughnut-chart></doughnut-chart>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col lg="4" md="6" cols="12">
        <v-card class="card-shadow border-radius-xl" @click="showBasicAlert">
          <div class="px-4 py-4">
            <div class="chart">
              <RadarChart></RadarChart>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col sm="4" cols="12">
        <v-card class="border-radius-xl overflow-hidden card-shadow">
          <div class="px-4 pb-0 pt-4">
            <p class="text-sm mb-0 text-capitalize font-weight-bold text-body">
              Xоцрогдол
            </p>
            <h5 class="font-weight-bolder text-h5 text-typo mb-0">
              1927цаг (дундаж)
              <span class="text-success text-sm font-weight-bolder">+15%</span>
            </h5>
          </div>
          <div>
            <div class="chart">
              <canvas id="chart-line-1" class="chart-canvas"></canvas>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col lg="4" md="6" cols="12">
        <v-card class="card-shadow border-radius-xl" @click="showBasicAlert">
          <div class="px-4 py-4">
            <div class="chart">
              <BarChartHorizontal></BarChartHorizontal>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col lg="4" md="6" cols="12">
        <v-card class="card-shadow border-radius-xl" @click="showBasicAlert">
          <div class="px-4 py-4">
            <div class="chart">
              <BubbleChart></BubbleChart>
            </div>
          </div>
        </v-card>
      </v-col>
      


    </v-row>
  </v-container>
</template>
<script>
import Chart from "chart.js/auto";
import ScoreWidget from "@/views/Dashboard/Widgets/ScoreWidget";
import RadarChart from "@/views/Pages/Widgets/RadarChart.vue";
import DoughnutChart from "@/views/Pages/Widgets/DoughnutChart.vue";
import BarChartHorizontal from "@/views/Pages/Widgets/BarChartHorizontal.vue";
import BubbleChart from "@/views/Pages/Widgets/BubbleChart.vue";

import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

export default {
  name: "Projects",
  data: function () {
    return { chartLine1: "chart-line-1" };
  },
  components: {
    ScoreWidget,
    DoughnutChart,
    RadarChart,
    BarChartHorizontal,
    BubbleChart
  },
  methods: {
    showBasicAlert() {
      this.$swal("Таны ашиглаж байгаа xувилбар туршилтын xувилбар байна. ");
    },
  },
  mounted() {
    var chart1 = document.getElementById(this.chartLine1).getContext("2d");
    var gradientStroke1 = chart1.createLinearGradient(0, 230, 0, 50);

    gradientStroke1.addColorStop(1, "rgba(203,12,159,0.02)");
    gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
    gradientStroke1.addColorStop(0, "rgba(203,12,159,0)"); //purple colors

    new Chart(chart1, {
      type: "line",
      data: {
        labels: ["9", "10", "11", "12", "1", "2", "3", "4", "5"],
        datasets: [
          {
            label: "Visitors",
            tension: 0.5,
            pointRadius: 0,
            borderColor: "#cb0c9f",
            borderWidth: 2,
            backgroundColor: gradientStroke1,
            data: [50, 45, 60, 60, 80, 65, 45, 60, 60],
            maxBarThickness: 6,
            fill: true,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#9ca2b7",
            },
          },
        },
      },
    });
  },
};
</script>
