<template>
  <v-card>
    <div class="px-4 py-4">
      <v-row>
        <v-col>
          <h6 class="text-h6 text-typo font-weight-bold">
            ЭЕШ сонгосон сурагчид 
          </h6>
        </v-col>
        <v-col>
          <v-btn
            elevation="0"
            color="yellow"
            class="text-capitalize"
            @click="_goTo"
          >
            Дэлгэрэнгүй
          </v-btn>
        </v-col>
      </v-row>
      <div class="card-padding">
        <div class="chart">
          <canvas id="categoriesChart" class="chart-canvas"></canvas>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
/*eslint-disable*/
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
Chart.register(ChartDataLabels);
export default {
  components: {},
  data() {
    return {
      chartLabels: null,
      chartNumbers: null,
    };
  },
  computed: {
    ...sync("*"),
  },
  methods: {
    _goTo() {
      this.$router.push({
        name: "StudentLessonChart",
      });
    },
    find() {
      // var x = await thissoril.ref
      //   .collection("schools-reports")
      //   .orderBy("averageScore", "desc")
      //   .get();
      // if (!x.empty) {
      //   this.averageScores = [];
      //   this.schoolLabels = [];
      //   this.numberOfParticipants = [];
      //   for (var schoolData of x.docs) {
      //     this.averageScores.push(schoolData.data().averageScore);
      //     this.numberOfParticipants.push(schoolData.data().numberOfStudents);
      //     this.schoolLabels.push(schoolData.data().schoolName);
      //     //this.schoolLabels.push("*****");
      //   }
      // }
      var xChart = document.getElementById("categoriesChart");
      if (xChart) {
        new Chart(xChart.getContext("2d"), {
          type: "bar",
          data: {
            labels: this.chartLabels,
            datasets: [
              // {
              //   label: "Watts",
              //   tension: 0.4,
              //   borderWidth: 0,
              //   borderRadius: 4,
              //   borderSkipped: false,
              //   backgroundColor: "#3A416F",
              //   data: this.averageScores,
              //   maxBarThickness: 6,
              //   datalabels: {
              //     color: "green",
              //     align: "end",
              //     anchor: "end",
              //   },
              // },
              {
                label: "Сонгосон тоо",
                tension: 0.4,
                borderWidth: 0,
                borderRadius: 4,
                borderSkipped: false,
                backgroundColor: "red",
                data: this.chartNumbers,
                maxBarThickness: 6,
                datalabels: {
                  color: "red",
                  align: "end",
                  anchor: "end",
                },
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                color: "#36A2EB",
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  padding: 15,
                  color: "#9ca2b7",
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#9ca2b7",
                },
              },
            },
          },
        });
      }
    },
  },
  created() {},
  props: {
    lessonCategories: {
      required: true,
      type: Array,
    },
  },
  mounted() {
    this.chartLabels = this.lessonCategories
      ? this.lessonCategories.map((el) => el.name)
      : [];

    this.chartNumbers = this.lessonCategories
      ? this.lessonCategories.map((el) => el.counter)
      : [];
    this.find();
  },
};
</script>
