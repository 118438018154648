<template>
  <v-card class="card-shadow border-radius-xl mt-6" id="educations">
    <div class="px-6 py-6 d-flex">
      <h5 class="text-h5 font-weight-bold text-typo">{{ title }}</h5>
      <v-btn
        elevation="0"
        small
        :ripple="false"
        height="21"
        class="
          border-radius-md
          font-weight-bolder
          px-3
          py-3
          badge-font-size
          ms-auto
          text-success
        "
        color="#cdf59b"
        @click="_addEducation"
        title="Боловсролын мэдээлэл оруулаx"
        >+</v-btn
      >
    </div>
    <v-list three-line>
      <v-list-item
        v-for="(educa, index) in educations"
        v-bind:key="index"
        ripple
        @click="_editEducation(educa, index)"
      >
        <v-list-item-content>
          <div class="d-flex justify-space-between">
            <span class="text-uppercase font-weight-bold caption"
              >{{ index + 1 }}. {{ educa.name }}</span
            >
            <small class="font-weight-regular" color="gray--text"
              >{{ educa.educationDegree }}
              <span v-if="educa.certifcateNumber"
                >, {{ educa.certifcateNumber }}</span
              ></small
            >
          </div>

          <p>
            <span v-if="educa.startDate">{{ educa.startDate }}</span>
            <span v-if="educa.endDate"> - {{ educa.endDate }}</span>
          </p>
        </v-list-item-content>

        <v-list-item-icon>
          <!-- <v-icon small class="mr-2"> mdi-pencil </v-icon> -->
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <v-dialog v-model="addEducationDialog" max-width="500px">
      <v-card class="pb-4">
        <v-card-title>
          <span class="headline">Боловсрол</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field v-model="editedEducation.name" label="Нэр" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.number="editedEducation.startDate"
                type="number"
                label="Орсон огноо"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.number="editedEducation.endDate"
                type="number"
                label="Төгссөн огноо"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedEducation.certifcateNumber"
                label="Гэрчилгээний дугаар"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="editedEducation.educationDegree"
                :items="educationDegrees"
                label="Боловсролын зэрэг"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-10">
          <v-btn
            :ripple="false"
            elevation="0"
            dark
            class="
              font-weight-bolder
              btn-danger
              bg-gradient-danger
              py-5
              px-6
              ms-2
            "
            small
            @click="_deleteEducation"
          >
            Устгаx
          </v-btn>
          <v-spacer />
          <v-btn color="blue" text @click="_closeEducationDialog">
            Цуцлаx
          </v-btn>
          <v-btn
               elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    text-capitalize
                    ms-auto
                    btn-primary
                    bg-gradient-primary
                    py-3
                    px-6
                    ms-3
                  "
            @click="_saveEducationDialog"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    educationDegrees: [
      "Мэргэжилгүй",
      "Мэргэжлийн курс",
      "Баклавар",
      "Магистр",
      "Доктор",
    ],
    educations: null,
    addEducationDialog: false,
    editedEducationIndex: -1,
    editedEducation: {
      name: null,
    },
  }),
  props: {
    // idd: {
    //   required: true,
    // },
    title: {
      required: true,
    },
    user: {
      required: true,
    },
  },
  methods: {
    _addEducation() {
      this.addEducationDialog = true;
    },
    _saveEducationDialog() {
      this.editedEducation.modifiedByRef = this.$store.state.userData.ref;
      this.editedEducation.modifiedByEmail = this.$store.state.userData.email;

      if (this.editedEducationIndex == -1) {
        this.user.ref.collection("educations").doc().set(this.editedEducation);
      } else {
        this.editedEducation.ref.update(this.editedEducation);
      }

      this.editedEducation = {};
      this._close();
    },
    _closeEducationDialog() {
      this.addEducationDialog = false;
      this.editedEducationIndex = -1;
    },
    _deleteEducation() {
      this.editedEducation.ref.delete().then(() => {
        this.editedEducation = {};
        this._close();
      });
    },
    _editEducation(educa, index) {
      this.addEducationDialog = true;
      this.editedEducation = educa;
      this.editedEducationIndex = index;
    },
    _close() {
      this.addEducationDialog = false;
      this.editedEducationIndex = -1;
    },
  },
  created() {
    console.log(this.user.ref.path);
    this.user.ref.collection("educations").onSnapshot((querySnapshot) => {
      this.educations = [];
      querySnapshot.forEach((doc) => {
        let eduData = doc.data();
        eduData.id = doc.id;
        eduData.ref = doc.ref;
        this.educations.push(eduData);
      });
    });
  },
};
</script>
