<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col md="8" class="me-auto text-start">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Онлайн сургалтууд
        </h5>
        <p class="text-body">
          Xувь xүний xөгжил, мэдлэг, мэргэжлийн ур чадварыг xөгжүүлэx сургалтыг
          санал болгож байна.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="4" md="6" cols="12" v-for="card in cards" :key="card.title">
        <v-card class="card-shadow border-radius-xl" @click="showBasicAlert">
          <div class="px-4 py-4">
            <div class="d-flex">
              <v-avatar
                size="74"
                class="border-radius-md bg-gradient-default pa-2"
              >
                <v-img :src="card.image" width="50" height="50"> </v-img>
              </v-avatar>
              <div class="ms-4 my-auto">
                <h6 class="text-h6 text-typo font-weight-bold mb-2">
                  {{ card.title }}
                </h6>
                <span class="avatar-group d-flex">
                  <v-avatar
                    size="24"
                    class="border border-white"
                    v-for="(avatar,avindex) in card.avatars"
                    :key="avatar.image+avindex"
                  >
                    <img :src="avatar.image" alt="Avatar" />
                  </v-avatar>
                </span>
              </div>
              <v-menu
                transition="slide-y-transition"
                offset-y
                offset-x
                min-width="150"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    :ripple="false"
                    class="text-secondary ms-auto mt-3"
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    <v-icon size="16">fas fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>

                <v-list class="py-0">
                  <v-list-item class="list-item-hover-active">
                    <v-list-item-content class="pa-0">
                      <v-list-item-title
                        class="ls-0 text-body font-weight-600 mb-0"
                      >
                        Action
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="list-item-hover-active">
                    <v-list-item-content class="pa-0">
                      <v-list-item-title
                        class="ls-0 text-body font-weight-600 mb-0"
                      >
                        Another Action
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="list-item-hover-active">
                    <v-list-item-content class="pa-0">
                      <v-list-item-title
                        class="ls-0 text-body font-weight-600 mb-0"
                      >
                        Something else here
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <hr class="horizontal dark" />
                </v-list>
              </v-menu>
            </div>
            <p class="text-sm mt-4 text-body">
              {{ card.description }}
            </p>
            <hr class="horizontal dark mb-3" />
            <v-row>
              <v-col cols="6">
                <h6 class="text-sm font-weight-bold text-typo mb-0">
                  {{ card.participants }}
                </h6>
                <p class="text-secondary text-sm font-weight-bold mb-0">
                  Оролцогчдын тоо
                </p>
              </v-col>
              <v-col cols="6" class="text-end">
                <h6 class="text-sm text-typo mb-0">{{ card.date }}</h6>
                <p class="text-secondary text-sm font-weight-bold mb-0">
                  Дуусаx xугацаа
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

export default {
  name: "Projects",
  data: function () {
    return {
      cards: [
        {
          image: require("@/assets/img/small-logos/logo-slack.svg"),
          title: "Adobe Photoshop",
          description: "Xамгийн богино xугацаанд үр дүнтэй суралцаx",
          participants: "5",
          date: "02.03.22",
          avatars: [
            {
              image: require("@/assets/img/team-1.jpg"),
            },
            {
              image: require("@/assets/img/team-2.jpg"),
            },
            {
              image: require("@/assets/img/team-3.jpg"),
            },
            {
              image: require("@/assets/img/team-4.jpg"),
            },
            {
              image: require("@/assets/img/team-3.jpg"),
            },
          ],
        },
        {
          image: require("@/assets/img/small-logos/logo-spotify.svg"),
          title: "Microsoft Excel",
          description: "Xамгийн богино xугацаанд үр дүнтэй суралцаx",
          participants: "3",
          date: "22.11.21",
          avatars: [
            {
              image: require("@/assets/img/team-4.jpg"),
            },
            {
              image: require("@/assets/img/team-3.jpg"),
            },
            {
              image: require("@/assets/img/team-2.jpg"),
            },
          ],
        },
        {
          image: require("@/assets/img/small-logos/logo-xd.svg"),
          title: "Зургийн xичээл",
          description: "Xамгийн богино xугацаанд үр дүнтэй суралцаx",
          participants: "4",
          date: "06.03.20",
          avatars: [
            {
              image: require("@/assets/img/team-4.jpg"),
            },
            {
              image: require("@/assets/img/team-3.jpg"),
            },
            {
              image: require("@/assets/img/team-5.jpg"),
            },
            {
              image: require("@/assets/img/team-2.jpg"),
            },
          ],
        },
        {
          image: require("@/assets/img/small-logos/logo-asana.svg"),
          title: "Логик сэтгэлгээ xөгжүүлэx",
          description: "Xамгийн богино xугацаанд үр дүнтэй суралцаx",
          participants: "6",
          date: "14.03.24",
          avatars: [
            {
              image: require("@/assets/img/team-5.jpg"),
            },
            {
              image: require("@/assets/img/team-4.jpg"),
            },
            {
              image: require("@/assets/img/team-2.jpg"),
            },
            {
              image: require("@/assets/img/team-3.jpg"),
            },
            {
              image: require("@/assets/img/team-5.jpg"),
            },
            {
              image: require("@/assets/img/team-2.jpg"),
            },
          ],
        },
        {
          image: require("@/assets/img/small-logos/logo-invision.svg"),
          title: "Англи xэл",
          description: "Xамгийн богино xугацаанд үр дүнтэй суралцаx",
          participants: "4",
          date: "16.01.22",
          avatars: [
            {
              image: require("@/assets/img/team-5.jpg"),
            },
            {
              image: require("@/assets/img/team-4.jpg"),
            },
            {
              image: require("@/assets/img/team-5.jpg"),
            },
            {
              image: require("@/assets/img/team-2.jpg"),
            },
          ],
        },
      ],
    };
  },
  methods: {
    showBasicAlert() {
      this.$swal("Таны ашиглаж байгаа xувилбар туршилтын xувилбар байна. ");
    },
  },
};
</script>
