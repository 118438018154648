<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    console.log(this.$store.state.userData.school)
  }
};
</script>
