<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-card class="border-radius-xl card-shadow mb-5">
        <div class="px-4 py-4 mt-2">
          <h1>{{ selectedYear }}/{{ selectedMonth }}/{{ selectedDay }}</h1>
          <div class="card-header-padding">
            <!-- <v-data-table
                  v-if="attendances"
                  :headers="headers"
                  :items="attendances"
                  hide-default-header
                  hide-default-footer
                  :items-per-page="-1"
                >
                  <template slot="item" slot-scope="props">
                    <tr :id="props.item.id">
                      <td class="text-center">{{ props.item.index }}</td>
                      <td>{{ props.item.text }}</td>
                    </tr>
                  </template>
                </v-data-table> -->

            <v-row>
              <v-col lg="auto" class="pa-0">
                <v-row justify="start">
                  <small
                    @click="selectedDay = day"
                    small
                    style="
                      cursor: pointer;
                      background: #dadada;
                      color: #000 !important;
                    "
                    class="
                      border-radius-sm
                      text-xxs
                      me-1
                      shadow-none
                      font-weight-bold
                      px-3
                      py-1
                    "
                    :class="
                      selectedDay == day
                        ? 'bg-gradient-success white--text'
                        : ''
                    "
                    :style="selectedDay == day ? 'color: white' : ''"
                    elevation="0"
                    :ripple="false"
                    v-for="(day, dindex) in days"
                    :key="'day-' + dindex"
                    >{{ day }}</small
                  >
                </v-row>
              </v-col>
              <v-col class="pa-0">
                <!-- <v-select
                return-object
                v-model="selectedMonth"
                :items="months"
                item-text="name"
                item-value="id"
                label="Сар сонгоx"
              >
              </v-select> -->

                <v-row justify="end">
                  <small
                    @click="selectedMonth = month"
                    small
                    style="
                      cursor: pointer;
                      background: #dadada;
                      color: #000 !important;
                    "
                    class="
                      border-radius-sm
                      text-xxs
                      me-1
                      shadow-none
                      font-weight-bold
                      px-3
                      py-1
                    "
                    :class="
                      selectedMonth == month
                        ? 'bg-gradient-warning white--text'
                        : ''
                    "
                    :style="selectedMonth == month ? 'color: white' : ''"
                    elevation="0"
                    :ripple="false"
                    v-for="(month, mindex) in months"
                    :key="'month-' + mindex"
                    >{{ month }}</small
                  >
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
      <v-row
        v-for="(dep, depIndex) in departments"
        :key="'department-' + depIndex"
      >
        <v-col
          cols="2"
          v-for="(program, programIndex) in dep.classGroups"
          :key="'program-' + programIndex"
        >
          <v-card class="border-radius-xl card-shadow">
            <h2 class="mb-0 mx-2 text-h3 font-weight-bolder text-typo">
              {{ dep.name }}{{ program.name.toUpperCase() }}
              <!-- {{ program.ref.path }} -->
            </h2>
            <div class="px-6 py-2 text-center">
              <div class="chart">
                <BarChartHorizontal
                  v-if="program.attendanceStatistics"
                  v-bind="$attrs"
                  :chartId="program.id"
                  :attendanceStatistics="program.attendanceStatistics"
                ></BarChartHorizontal>
              </div>
              <!-- <h1 class="text-gradient text-success font-weight-bolder text-h1">
                <span v-if="program.attendanceStatistics">{{program.attendanceStatistics["status-1"]}}</span>
                <span v-if="program.attendanceStatistics">{{program.attendanceStatistics["status-2"]}}</span>
                <span v-if="program.attendanceStatistics">{{program.attendanceStatistics["status-3"]}}</span>
                <span v-if="program.attendanceStatistics">{{program.attendanceStatistics["status-4"]}}</span>
              </h1> -->
              <!-- <h2 class="mb-0 text-h1 font-weight-bolder text-typo">
                {{ dep.name }}{{ program.name.toUpperCase() }}
              </h2>
              <p class="opacity-8 mb-0 text-sm text-typo"></p> -->
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { sync } from "vuex-pathify";
import BarChartHorizontal from "./BarChartHorizontal.vue";
// const fb = require("@/firebaseConfig.js");
export default {
  name: "smart-home",
  components: { BarChartHorizontal },

  computed: {
    ...sync("*"),
    headers() {
      var list = [];
      list.push({
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
      });
      return list;
    },
  },
  data() {
    return {
      attendances: null,
      departments: null,
      selectedYear: null,
      selectedDay: null,
      selectedMonth: null,
      months: [1, 2, 3, 4, 5, 6, 9, 10, 11, 12],
      days: [
        1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
        22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
    };
  },
  created() {
    this.selectedMonth = new Date().getMonth() + 1;
    this.selectedDay = new Date().getDate();
    this.selectedYear = new Date().getFullYear();
    console.log(this._getFullYearDate());
    this._getDepartments(this.$store.state.userData.school.currentYear);

    console.log(this.$store.state.userData.school);
  },
  watch: {
    selectedDay() {
      this._getDepartments(this.$store.state.userData.school.currentYear);
    },
    selectedMonth() {
      this._getDepartments(this.$store.state.userData.school.currentYear);
    },
  },
  methods: {
    _getFullYearDate() {
      return (
        this.selectedYear + "-" + this.selectedMonth + "-" + this.selectedDay
      );
    },
    _getDepartments(startYear) {
      this.userData.school.ref
        .collection("departments")
        .where("startYear", "==", startYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            this.countOfPrograms = 0;
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .onSnapshot((querySnapshot) => {
                dep.classGroups = [];
                dep.numberOfStudents = 0;
                querySnapshot.forEach(async (doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  let prog = doc.data();
                  prog.id = doc.id;
                  prog.ref = doc.ref;
                  prog.attendanceStatistics = null;
                  prog.ref
                    .collection("attendances-" + startYear)
                    .doc(this._getFullYearDate())
                    .onSnapshot((doc) => {
                      if (doc.exists) {
                        prog.attendanceStatistics = doc.data();
                        console.log(prog)
                        // this.$forceUpdate();
                      }
                    });
                  // if (
                  //   prog.numberOfStudents != undefined &&
                  //   prog.numberOfStudents != null
                  // )
                  //   dep.numberOfStudents =
                  //     dep.numberOfStudents + prog.numberOfStudents;
                  dep.classGroups.push(prog);
                });
              });
            this.departments.push(dep);
          });
        });
    },
  },
};
</script>

 