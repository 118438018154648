<template>
  <v-card>
    <div class="px-4 py-4">
      <h6 class="text-h6 text-typo font-weight-bold">
        Сурагчдын тоо (бүлгээр) <span v-if="this.classGroup">/ {{ this.classGroup.length }}</span>
      </h6>

      <div class="card-padding">
        <div class="chart">
          <canvas id="classGroupChart" class="chart-canvas"></canvas>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
/*eslint-disable*/
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
Chart.register(ChartDataLabels);
export default {
  components: {},
  data() {
    return {
      chartNumbers: null,
      chartLabels: null,
    };
  },
  computed: {
    ...sync("*"),
  },
  methods: {
    find() {
      setTimeout(() => {
        var xChart = document.getElementById("classGroupChart");
        if (xChart) {
          new Chart(xChart.getContext("2d"), {
            type: "bar",
            data: {
              labels: this.chartLabels,
              datasets: [
                // {
                //   label: "Watts",
                //   tension: 0.4,
                //   borderWidth: 0,
                //   borderRadius: 4,
                //   borderSkipped: false,
                //   backgroundColor: "#3A416F",
                //   data: this.averageScores,
                //   maxBarThickness: 6,
                //   datalabels: {
                //     color: "green",
                //     align: "end",
                //     anchor: "end",
                //   },
                // },
                {
                  label: "Сонгосон тоо",
                  tension: 0.4,
                  borderWidth: 0,
                  borderRadius: 4,
                  borderSkipped: false,
                  backgroundColor: "#4c8bf5",
                  data: this.chartNumbers,
                  maxBarThickness: 6,
                  datalabels: {
                    color: "#4c8bf5",
                    align: "end",
                    anchor: "end",
                  },
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  color: "#36A2EB",
                },
              },
              interaction: {
                intersect: false,
                mode: "index",
              },
              scales: {
                y: {
                  grid: {
                    drawBorder: false,
                    display: false,
                    drawOnChartArea: true,
                    drawTicks: false,
                    borderDash: [5, 5],
                  },
                  ticks: {
                    display: true,
                    padding: 15,
                    color: "#9ca2b7",
                  },
                },
                x: {
                  grid: {
                    drawBorder: false,
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: false,
                    borderDash: [5, 5],
                  },
                  ticks: {
                    beginAtZero: true,
                    display: true,
                    padding: 10,
                    color: "#9ca2b7",
                  },
                },
              },
            },
          });
        }
      }, 1000);
    },
  },

  props: {
    classGroup: {
      required: true,
      type: Array,
    },
  },
  created() {},
  mounted() {
    this.chartNumbers = [];
    this.chartLabels = [];
    for (var group of this.classGroup) {
      this.chartNumbers.push(group.numberOfStudents);
      this.chartLabels.push(group.name);
    }
    this.find();
  },
};
</script>
