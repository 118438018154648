<template>
  <v-container fluid class="mt-10 py-6" v-if="user">
    <v-row>
      <v-col lg="9" class="mx-auto">
        <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
          <v-row>
            <v-col cols="auto">
              <v-avatar width="74" height="74" class="shadow border-radius-lg">
                <img
                  src="@/assets/img/user.jpg"
                  alt="Avatar"
                  class="border-radius-lg"
                />
              </v-avatar>
            </v-col>
            <v-col cols="auto" class="my-auto">
              <div class="h-100">
                <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                  {{ user.firstName }}
                </h5>
                <p class="mb-0 font-weight-bold text-body text-sm">
                  {{ user.roleName }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <BasicInfo v-bind="$attrs" :user="user"></BasicInfo>

        <UlsiinShalgaltBurtgeh
          v-bind="$attrs"
          :user="user"
          title="Шалгалтын дүнгүүд"
        ></UlsiinShalgaltBurtgeh>
        <!-- <PaymentTable :user="user" v-bind="$attrs"></PaymentTable> -->
        <BagshTailbarBichih
          v-bind="$attrs"
          :user="user"
          title="Багшийн дүгнэлт"
        ></BagshTailbarBichih>

        <BodyMassIndex
          v-bind="$attrs"
          :user="user"
          title="Биеийн жин, өндөр эрүүл мэндийн дүгнэлт"
        ></BodyMassIndex>

        <DeleteAccountStudent
          v-bind="$attrs"
          :user="user"
          title="Шилжилт xөдөлгөөн"
        ></DeleteAccountStudent>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BasicInfo from "@/views/Pages/Profile/Widgets/BasicInfo.vue";
// import PaymentTable from "./Widgets/PaymentTable.vue";
import DeleteAccountStudent from "./Widgets/DeleteAccountStudent.vue";
import UlsiinShalgaltBurtgeh from "./Widgets/UlsiinShalgaltBurtgeh.vue";
import BagshTailbarBichih from "./Widgets/BagshTailbarBichih.vue";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  name: "Settings",
  components: {
    BasicInfo,
    // PaymentTable,
    DeleteAccountStudent,
    UlsiinShalgaltBurtgeh,
    BagshTailbarBichih,
    BodyMassIndex: () => import("./Widgets/BodyMassIndex"),
  },
  data() {
    return {
      user: null,
      switche: true,
      educations: null,
    };
  },
  computed: {
    ...sync("*"),
  },
  props: {
    userrefid: {
      required: false,
    },
  },
  created() {
    // fb.db.collection()
    // userrefid
    if (this.userrefid) {
      fb.db
        .doc(this.userrefid)
        .get()
        .then((doc) => {
          let user = doc.data();
          user.id = doc.id;
          user.ref = doc.ref;
          this.user = user;
        });
    } else {
      this.userData.ref.onSnapshot((doc) => {
        let user = doc.data();
        user.id = doc.id;
        user.ref = doc.ref;
        this.user = user;
      });
    }
    console.log(this.user);

    // fb.db
    //   .doc("user")
    //   .collection("educations")
    //   .onSnapshot((querySnapshot) => {
    //     this.educations = [];
    //     querySnapshot.forEach((doc) => {
    //       let eduData = doc.data();
    //       eduData.id = doc.id;
    //       eduData.ref = doc.ref;
    //       this.educations.push(eduData);
    //     });
    //   });
    // fb.db
    //   .doc(user.ref.path)
    //   .collection("jobs")
    //   .onSnapshot((querySnapshot) => {
    //     this.jobs = [];
    //     querySnapshot.forEach((doc) => {
    //       let eduData = doc.data();
    //       eduData.id = doc.id;
    //       eduData.ref = doc.ref;
    //       this.jobs.push(eduData);
    //     });
    //   });
    // fb.db
    //   .doc(user.ref.path)
    //   .collection("languages")
    //   .onSnapshot((querySnapshot) => {
    //     this.languages = [];
    //     querySnapshot.forEach((doc) => {
    //       let eduData = doc.data();
    //       eduData.id = doc.id;
    //       eduData.ref = doc.ref;
    //       this.languages.push(eduData);
    //     });
    //   });
  },
  methods: {},
};
</script>
