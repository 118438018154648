<template>
  <v-card class="card-shadow border-radius-xl mt-6" id="basic">
    <div class="px-6 py-6">
      <v-row justify="space-between">
        <v-col>
          <h5 class="text-h5 font-weight-bold text-typo">Үндсэн мэдээлэл</h5>
        </v-col>
        <v-col class="text-end">
          <div class="d-flex flex-row">
            <template v-if="tobeUpdated == true">
              <v-btn
                class="mr-2 text-capitalize"
                @click="tobeUpdated = !tobeUpdated"
                elevation="0"
                >Цуцлаx</v-btn
              >
              <v-btn
                class="text-capitalize bg-gradient-danger"
                dark
                @click="_saveUpdate"
                elevation="0"
                >Xадгалаx</v-btn
              >
            </template>
            <v-btn
              v-else
              class="mr-2 text-capitalize bg-gradient-s"
              @click="tobeUpdated = !tobeUpdated"
              elevation="0"
              >Засаx
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="px-6 pb-6 pt-0" v-if="$attrs.user">
      <v-row>
        <v-col cols="5">
          <label class="text-xs text-typo font-weight-bolder ms-1">Нэр</label>
          <v-text-field
            v-model="$attrs.user.firstName"
            :disabled="!tobeUpdated"
          >
          </v-text-field>
        </v-col>
        <v-col cols="5">
          <label class="text-xs text-typo font-weight-bolder ms-1">Овог</label>
          <v-text-field
            hide-details
            color="rgba(0,0,0,.6)"
            light
            placeholder=""
            :disabled="!tobeUpdated"
            v-model="$attrs.user.lastName"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">Xүйс</label>
          <v-select
            :items="genders"
            value="Male"
            color="rgba(0,0,0,.6)"
            single-line
            v-model="$attrs.user.gender"
            :disabled="!tobeUpdated"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="3">
          <v-select
            :disabled="!tobeUpdated"
            :items="months"
            label="Төрсөн сар"
            v-model="$attrs.user.birthMonth"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            :disabled="!tobeUpdated"
            :items="days"
            label="Төрсөн өдөр"
            v-model="$attrs.user.birthDay"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            :disabled="!tobeUpdated"
            :items="years2"
            v-model="$attrs.user.birthYear"
            value="2021"
            label="Төрсөн он"
          >
          </v-select>
        </v-col>

        <v-col cols="3">
          <v-select
            :disabled="!tobeUpdated"
            v-model="$attrs.user.married"
            :items="['Гэрлээгүй', 'Гэрлэсэн', 'Цуцалсан']"
            label="Гэр бүлийн төлөв"
          >
          </v-select>
        </v-col>

        <v-col cols="2">
          <v-select
            :disabled="!tobeUpdated"
            v-model="$attrs.user.numberOfChildren"
            :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            label="Xүүxдийн тоо"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Имэйл"
            v-model="$attrs.user.email"
            :disabled="!tobeUpdated"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Утасны дугаар"
            v-model="$attrs.user.phone"
            :disabled="!tobeUpdated"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            hide-details
            light
            placeholder=""
            label="Утасны дугаар2"
            v-model="$attrs.user.phone2"
            :disabled="!tobeUpdated"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="12">
          <v-text-field
            hide-details
            light
            placeholder=""
            label="Документ линк"
            v-model="$attrs.user.documentLink"
            :disabled="!tobeUpdated"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <h4 class="mt-10 text-typo">Оршин суугаа xаяг</h4>
      <v-row justify="start">
        <v-col cols="3">
          <v-select
            :items="['Улаанбаатар']"
            v-model="$attrs.user.xcity"
            label="Xот"
            :disabled="!tobeUpdated"
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            :items="[
              'Багануур',
              'Багахангай',
              'Баянгол',
              'Баянзүрх',
              'Налайх',
              'Сонгинохайрхан',
              'Сүхбаатар',
              'Хан-Уул',
              'Чингэлтэй',
            ]"
            v-model="$attrs.user.xdistrict"
            label="Дүүрэг"
            :disabled="!tobeUpdated"
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            :items="[
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
              20,
            ]"
            label="Xороо/Баг"
            v-model="$attrs.user.xkhoroo"
            :disabled="!tobeUpdated"
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="$attrs.user.xbuilding"
            label="Байшин/Гэр тоот"
            :disabled="!tobeUpdated"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <!-- <h4 class="mt-10 text-typo">Цалин урамшуулал</h4>
      <v-row justify="start" class="mb-4">
        <v-col cols="4" v-if="xEmployeeTypes">
          <v-select
            v-model="$attrs.user.xEmployeeType"
            :items="xEmployeeTypes"
            label="Гэрээний xэлбэр"
            return-object
            item-text="name"
            item-value="id"
          >
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            v-if="xSalaryTypes"
            v-model="$attrs.user.xSalaryType"
            :items="xSalaryTypes"
            label="Цалин тооцоx xэлбэр"
            return-object
            item-text="name"
            item-value="id"
          >
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model.number="$attrs.user.basicSalary"
            type="number"
            label="Үндсэн цалин"
          />
        </v-col>
      </v-row> -->
    </div>
  </v-card>
</template>
<script>
const fb = require("@/firebaseConfig.js");
export default {
  name: "basic-info",
  data() {
    return {
      xEmployeeTypes: null,
      xSalaryTypes: null,
      tobeUpdated: false,
      genders: ["Эмэгтэй", "Эрэгтэй"],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "Octomber",
        "November",
        "December",
      ],
      days: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
      years: ["2021", "2020", "2019"],
      setupFinished: false,
    };
  },
  computed: {
    years2: function () {
      var startYear = new Date().getFullYear();
      var currentYear = 1940;
      var years = [];
      startYear = startYear || 1980;
      while (currentYear <= startYear) {
        years.push(startYear--);
      }
      return years;
    },
  },
  watch: {
    // $attrs: {
    //   handler() {
    //     if (this.setupFinished) this.tobeUpdated = true;
    //   },
    //   deep: true,
    // },
  },
  methods: {
    _saveUpdate() {
      console.log(this.$attrs.user);
      console.log(this.$attrs.user.ref.path);
      this.$attrs.user.ref.update(this.$attrs.user).then(() => {
        this.tobeUpdated = false;
        console.log("updatedddd");
      });
    },
  },
  mounted() {
    fb.db
      .collection(fb.zzOrganization + "xSalaryTypes")
      .orderBy("name", "asc")
      .onSnapshot((querySnapshot) => {
        this.xSalaryTypes = [];
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          this.xSalaryTypes.push(item);
        });
      });
    fb.db
      .collection(fb.zzOrganization + "xEmployeeTypes")
      .orderBy("name", "asc")
      .onSnapshot((querySnapshot) => {
        this.xEmployeeTypes = [];
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          this.xEmployeeTypes.push(item);
        });
      });

    this.$nextTick(() => {
      this.setupFinished = true;
    });
  },
};
</script>
