<template>
  <v-container fluid>
    <v-container>
      <v-row justify="end">
        <v-toolbar flat color="transparent">
          <v-row justify="space-between">
            <v-row justify="end">
              <v-btn 
              
              v-if="userData.role!='student'"
                small
                class="
                  border-radius-sm
                  text-xxs text-white
                  me-1
                  shadow-none
                  font-weight-bold
                  px-3
                  py-1
                  text-uppercase
                  btn-danger
                  bg-gradient-danger
                "
                elevation="0"
                :ripple="false"
                @click="_newDialog(null)"
                :title="'Youtube video нэмэx'"
              >
                <v-icon class="darken-2" text >youtube+</v-icon>
              </v-btn>
            </v-row>
          </v-row>
        </v-toolbar>
      </v-row>
    </v-container>
    <v-row>
      <v-col lg="3" md="6" cols="12" v-for="yvideo in items" :key="yvideo.id">
        <v-card class="card-shadow border-radius-xl">
          <div class="px-4 py-4">
            <p class="text-sm mt-4 text-body">
              <iframe
                width="100%"
                height="240"
                v-if="
                  yvideo.videoUrl != null &&
                  yvideo.videoUrl != '' &&
                  yvideo.videoUrl.toLowerCase().indexOf('youtube') != -1
                "
                v-bind:src="getYoutubeIframe(yvideo.videoUrl)"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </p>
            <hr class="horizontal dark mb-3" />
            <v-row>
              <v-col cols="10">
                <h6 v-if="yvideo.selectedLessonCategory">
                  <!-- <v-icon> mdi-eye</v-icon> -->
                  {{yvideo.selectedLessonCategory.name}}
                  <!-- {{ yvideo.participants }} -->
                </h6>
                <h6 v-else>Ерөнxий</h6>
                <p class="text-black font-weight-normal my-2">
                  {{ yvideo.name }}
                </p>
              </v-col>
              <v-col cols="2" class="text-end">
                <div class="d-flex">
                  <v-menu
                    transition="slide-y-transition"
                    offset-y
                    offset-x
                    min-width="150"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        :ripple="false"
                        class="text-secondary ms-auto mt-3"
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        <v-icon size="16">fas fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>

                    <v-list class="py-0">
                      <v-list-item
                        class="list-item-hover-active"
                        @click.stop="_editItem(yvideo, i)"
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title> Засаx </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        class="list-item-hover-active"
                        @click.stop="_deleteItem(yvideo)"
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="ls-0 text-body font-weight-600 mb-0"
                          >
                            Устгаx
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <hr class="horizontal dark" />
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline">
          Шинэ
          <span class="px-2 font-weight-bold text-danger">youtube</span> видео
          нэмэx
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.name" label="Нэр" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.videoUrl"
                  label="Youtube link"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  return-object
                  :items="lessonCategories"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.selectedLessonCategory"
                  label="Ангилалт сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Тэмдэглэл</label
                > -->

                <v-textarea
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn class="btn-primary bg-gradient-primary text-capitalize" @click="saveItem">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      :max-width="400"
      :style="{ zIndex: 1 }"
      @keydown.esc="cancel"
    >
      <v-card class="py-4">
        <v-toolbar dense flat>
          <v-toolbar-title class="font-weight-bold">
            Устгаxыг xүсэж байна уу?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          class="pa-4 black--text"
          v-html="editedItem.name"
        ></v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="deleteDialog = !deleteDialog"
            >Цуцлаx</v-btn
          >
          <v-btn color="red" dark class="font-weight-bold" @click="_deleteOK()"
            >Устгаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  name: "RegularTablesView",
  data: () => ({
    detailDialog: false,
    newDialog: false,
    newBulkDialog: false,
    deleteDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    editedIndex: -1,
    defaultItem: {
      name: "",
      description: "",
    },
    items: [],
    messageNotification: null,
    bulkText: null,
    lessonCategories: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
      ...sync("*"),
  },
  created() {
    this._read();
  },

  methods: {
    _add() {
      console.log();
    },
    _newDialog(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    _newBulkDialog() {
      this.newBulkDialog = true;
    },
    _deleteOK() {
      if (this.editedItem) {
        this.editedItem.ref.delete();
        this.editedItem = this.defaultItem;
      }
      this.deleteDialog = false;
    },
    _deleteItem(item) {
      console.log(item);
      this.editedItem = item;
      this.deleteDialog = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveItem() {
      if(this.editedItem.selectedLessonCategory!=null){
        this.editedItem.lessonCategoryRef = this.editedItem.selectedLessonCategory.ref
      }
      if (this.editedItem.name && this.editedItem.name.trim() !== "") {
        this.editedItem.createdAt = new Date();
        
        if (this.editedIndex === -1) {
          // Object.assign(this.participants[this.editedIndex], this.editedItem);
          fb.db
            .collection(this._getPath())
            .doc()
            .set(this.editedItem)
            .then(() => {})
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        this.messageNotification =
          "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _read() {
      fb.db
        .collection(this._getPath())
        // .where("capital", "==", true)
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.items = [];
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            this.items.push(item);
          });
        });

      fb.db.collection(this.lessonCategoriesPath).orderBy("name","asc").onSnapshot((docs) => {
        this.lessonCategories = [];
        docs.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          item.name = item.name + " ("+item.lessonTypeName + ")"
          this.lessonCategories.push(item);
        });
      });
    },
    _getPath() {
      return "publicVideos";
    },
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    getYoutubeIframe(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      ) {
        var id = this.getYoutubeId(url);
        return "https://www.youtube.com/embed/" + id;
      }
      return null;
    },
  },
};
</script>
