<template>
  <v-card
    class="card-shadow border-radius-xl mt-6"
    id="workhistory"
    v-if="xitems"
  >
    <div class="px-6 py-6 d-flex">
      <h5 class="text-h5 font-weight-bold text-typo">{{ title }}</h5>
      <v-btn
        elevation="0"
        small
        :ripple="false"
        height="21"
        class="
          border-radius-md
          font-weight-bolder
          px-3
          py-3
          badge-font-size
          ms-auto
          text-success
        "
        color="#cdf59b"
        @click="_addEducation"
        >+</v-btn
      >
    </div>
    <v-data-table :headers="headers" :items="xitems" :items-per-page="-1">
      <template slot="item" slot-scope="props">
        <tr>
          <td>{{ props.item.index }}</td>
          <td>{{ props.item.classGroup }} анги</td>
          <td>{{ props.item.description }}</td>
          <td>
            <template>
              <v-icon small class="mr-2" @click.stop="_delete(props.item)">
                mdi-delete
              </v-icon>
              <v-icon
                small
                class="mr-2"
                @click.stop="_editEducation(props.item, props.item.index)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="addEducationDialog" max-width="600px" max-height="800px">
      <v-card class="pb-4">
        <v-card-title>
          <span class="headline">Багшийн дүгнэлт</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4" sm="4" md="4">
              <v-select
                v-model="xitem.classGroup"
                label="Анги сонгоx"
                :items="[1,2,3,4,5,6,7,8,9,10,11,12]"
                return-object
                item-text="name"
                item-value="id"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                v-model="xitem.description"
                hide-details
                outlined
                label="Тэмдэглэгээ"
                color="rgba(0,0,0,.6)"
                value="Say a few words about who you are or what you're working on."
                class="
                  font-size-input
                  border
                  text-light-input
                  border-radius-md
                  mt-2
                "
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-10">
          <v-btn
            :ripple="false"
            elevation="0"
            dark
            class="
              font-weight-bolder
              btn-danger
              bg-gradient-danger
              py-5
              px-6
              ms-2
            "
            small
            @click="_deleteEducation"
          >
            Устгаx
          </v-btn>
          <v-spacer />
          <v-btn color="blue" text @click="_closeEducationDialog">
            Цуцлаx
          </v-btn>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              ms-auto
              btn-primary
              bg-gradient-primary
              py-3
              px-6
              ms-3
            "
            @click="_save"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  data: () => ({
    dateMenu: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuOpen: false,
    collectionPath: "teacherReports",
    xitems: null,
    addEducationDialog: false,
    editedEducationIndex: -1,
    xitem: {
      name: null,
    },
    closeAccount: false,
  }),
  computed: {
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },

    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
          width: "1%",
        },
        {
          text: "Анги",
          align: "start",
          sortable: true,
          value: "classGroup",
        },

        {
          text: "Тайлбар",
          align: "start",
          sortable: true,
          value: "description",
        },
      ];
    },
  },
  props: {
    // idd: {
    //   required: true,
    // },
    title: {
      required: true,
    },
    user: {
      required: true,
    },
  },
  methods: {
    _delete(item) {
      var yes = confirm("Та үүнийг устгаxдаа итгэлтэй байна уу?");
      if (yes) {
        item.ref.delete().then(() => {
          this._close();
        });
      }
    },
    _addEducation() {
      this.addEducationDialog = true;
    },
    _save() {
      this.xitem.modifiedByRef = this.$store.state.userData.ref;
      this.xitem.modifiedByEmail = this.$store.state.userData.email;

      this.xitem.accountClosed = this.closeAccount;

      if (this.editedEducationIndex == -1) {
        this.user.ref
          .collection(this.collectionPath)
          .doc()
          .set(this.xitem)
          .then(() => {
            this.user.ref
              .update({ accountClosed: this.xitem.accountClosed })
              .then(() => {
                this.xitem = {};
                this._close();
              });
          });
      } else {
        this.xitem.ref.update(this.xitem).then(() => {
          this.user.ref
            .update({ accountClosed: this.xitem.accountClosed })
            .then(() => {
              this.xitem = {};
              this._close();
            });
        });
      }
    },
    _closeEducationDialog() {
      this.addEducationDialog = false;
      this.editedEducationIndex = -1;
    },
    _deleteEducation() {
      this.xitem.ref.delete().then(() => {
        this.xitem = {};
        this._close();
      });
    },
    _editEducation(educa, index) {
      this.addEducationDialog = true;
      this.xitem = educa;
      this.editedEducationIndex = index;
    },
    _close() {
      this.addEducationDialog = false;
      this.editedEducationIndex = -1;
    },
  },
  created() {
    if (this.zzschool) {
      fb.db
        .collection(this.zzschool + "/xOutOffWork")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.reasonOutOfWork = [];
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            this.reasonOutOfWork.push(item);
          });
        });
    }

    this.user.ref
      .collection(this.collectionPath)
      .onSnapshot((querySnapshot) => {
        this.xitems = [];
        var counter = 0;
        querySnapshot.forEach((doc) => {
          counter++;
          let eduData = doc.data();
          eduData.id = doc.id;
          eduData.ref = doc.ref;
          eduData.index = counter;
          this.xitems.push(eduData);
        });
      });
  },
};
</script>
