const fb = require("@/firebaseConfig.js");
import moment from "moment";
import { store } from "@/store.js"

export default {
  methods: {

    async xReadSchool(fb, path) {
      return await fb.db.doc(path).get()
    },
    async xreadUserData(user, newLogin) {
      var doc = await fb.db
        .collection("users").doc(user.uid)
        .where("email", "==", user.user.email)
        .limit(1)
        .get()

      if (!doc.empty) {
        var userObj = doc.docs[0];
        if (userObj.exists) {
          let user = userObj.data();
          user.ref = userObj.ref;
          user.id = userObj.id;

          // var dep = await user.department.get();
          // let department = dep.data()
          // department.ref = dep.ref
          // department.id = dep.id
          user.ref.update({ lastLoggedInAt: new Date() })
          store.set("userData", user)
          store.set("userRefPath", user.ref.path)
          store.set("loggedEmail", user.email)
          store.set("logged", true)
          // store.set("userData", { department: department, user: user })
          // store.set("department", department)
          // store.set("departmentRef", department.ref.path)

          // localStorage.setItem("taranterp@userDepartment", JSON.stringify(department.data()));
          // store.set("currentUserEmail",user.email)
          if (newLogin) {
            fb.db.collection("logs").doc(this.$store.state.userData.id).update({ loggedInAt: new Date(), logged: true }).then(() => {
              fb.db.collection("logs").doc(this.$store.state.userData.id).onSnapshot((doc) => {
                store.set("logged", doc.data().logged)
              })
            })

          }
        } else {
          store.set("userData", null)
          store.set("userRefPath", null)
          store.set("loggedEmail", null)
          store.set("logged",null)
        }
      }
    },
    formatXDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).format("MM/DD/YYYY HH:mm");
    },
    formatXDay(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).format("DD");
    },
    formatXDate2(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },

  }
}