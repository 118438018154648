import axios from "axios";

export default {
  async getESIS_TOKEN(uName, pWord) {
    this.loading = true;
    return await axios
      .post(
        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
        {
          username: uName,
          password: pWord,
        }
      )
  },
  async getESIS_STUDY_PROGRAMS(school, ktoken) {
    var res = await axios
      .post(
        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
        {
          url: "https://hub.esis.edu.mn/svc/api/hub/program/list",
          token: ktoken,
        }
      )
    if (res.status === 200) {
      res.data.RESULT.forEach((schoolProgram) => {
        school.ref
          .collection("schoolPrograms")
          .doc(schoolProgram.PROGRAM_OF_STUDY_ID.toString())
          .set(schoolProgram).then(async () => {
            var stages = await axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                {
                  url:
                    "https://hub.esis.edu.mn/svc/api/hub/program/stage/list/" +
                    schoolProgram.PROGRAM_OF_STUDY_ID,
                  token: ktoken,
                }
              )
            if (stages.status === 200) {
              stages.data.RESULT.forEach((schoolStage) => {
                school.ref
                  .collection("schoolPrograms")
                  .doc(schoolProgram.PROGRAM_OF_STUDY_ID.toString())
                  .collection("stages")
                  .doc(schoolStage.PROGRAM_STAGE_ID.toString())
                  .set(schoolStage).then(async () => {
                    var plans = await axios
                      .post(
                        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                        {
                          url:
                            "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/list/" +
                            schoolProgram.PROGRAM_OF_STUDY_ID +
                            "/" +
                            schoolStage.PROGRAM_STAGE_ID.toString(),
                          token: ktoken,
                        }
                      )
                    if (plans.status === 200) {
                      plans.data.RESULT.forEach((coursePlan) => {
                        school.ref
                          .collection("schoolPrograms")
                          .doc(
                            schoolProgram.PROGRAM_OF_STUDY_ID.toString()
                          )
                          .collection("stages")
                          .doc(
                            schoolStage.PROGRAM_STAGE_ID.toString()
                          )
                          .collection("plans")
                          .doc(
                            coursePlan.PROGRAM_PLAN_ID.toString()
                          )
                          .set(coursePlan).then(async () => {
                            var courses = await axios
                              .post(
                                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                                {
                                  url:
                                    "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/" +
                                    schoolProgram.PROGRAM_OF_STUDY_ID +
                                    "/" +
                                    schoolStage.PROGRAM_STAGE_ID.toString() +
                                    "/" +
                                    coursePlan.PROGRAM_PLAN_ID,
                                  token: ktoken,
                                }
                              )
                            if (courses.status === 200) {
                              courses.data.RESULT.forEach(
                                (course) => {
                                  course.createdAt =
                                    new Date();
                                  school.ref
                                    .collection(
                                      "schoolPrograms"
                                    )
                                    .doc(
                                      schoolProgram.PROGRAM_OF_STUDY_ID.toString()
                                    )
                                    .collection("stages")
                                    .doc(
                                      schoolStage.PROGRAM_STAGE_ID.toString()
                                    )
                                    .collection("plans")
                                    .doc(
                                      coursePlan.PROGRAM_PLAN_ID.toString()
                                    )
                                    .collection("courses")
                                    .doc(
                                      course.COURSE_ID.toString()
                                    )
                                    .set(course);
                                })
                            }
                          })
                      })
                    }
                  })
              })
            }
            console.log(stages)
            //
          })
      })
      return res.data.RESULT
    }
    return null
  },

  async getESIS_GROUPS(school, ktoken, fbdb) {
    var res = await axios
      .post(
        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
        {
          url: "https://hub.esis.edu.mn/svc/api/hub/group/list",
          token: ktoken,
        }
      )

    if (res.status === 200) {
      school.ref.update({
        _esis_groupsInfo_updatedAt: new Date(),
      });
      var batch = fbdb.batch();
      var counter = 0;
      res.data.RESULT.forEach((item) => {
        counter++;
        console.log(counter, item.STUDENT_GROUP_NAME)
        batch.set(
          school.ref
            .collection(
              "departments-" + school.currentYear
            )
            .doc("department-" + item.ACADEMIC_LEVEL),
          {
            createdAt: new Date(),
            deleted: false,
            index: parseInt(item.ACADEMIC_LEVEL),
            name: item.ACADEMIC_LEVEL,
            numberOfStudents: 0,
            startYear: school.currentYear,
          }
        );
        item.name = item.STUDENT_GROUP_NAME;
        item.fullName = item.STUDENT_GROUP_NAME;
        item.startYear = school.currentYear;
        item.departmentName = item.ACADEMIC_LEVEL;
        item.createdAt = new Date();
        item.deleted = false;
        batch.set(
          school.ref
            .collection(
              "departments-" + school.currentYear
            )
            .doc("department-" + item.ACADEMIC_LEVEL)
            .collection("programs")
            .doc(item.STUDENT_GROUP_ID.toString()),
          item
        );
      });
      batch.commit().then(() => {
        school.ref.update({ numberOfGroups: counter })
      });
    } else {
      alert("Мэдээлэл татаxад алдаа гарлаа.");
      this.fb.db.collection("esis-connection-failed").doc({
        createdAt: new Date(),
        accessLink:
          "https://hub.esis.edu.mn/svc/api/hub/teacher/list",
        school: school,
      });
    }

  },

  async getESIS_TEACHERS(school, ktoken, fbdb) {
    var res = await axios
      .post(
        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
        {
          url: "https://hub.esis.edu.mn/svc/api/hub/teacher/list",
          token: ktoken,
        }
      )
    if (res.status === 200) {
      school.ref.update({
        _esis_teachers_updatedAt: new Date(),
      });
      var batch = fbdb.batch();
      var counter = 0
      res.data.RESULT.forEach((teacher) => {
        counter++
        teacher.accountClosed = false;
        teacher.deleted = false;
        teacher.createdAt = new Date();
        teacher.role = "teacher";
        teacher.roleName = "Багш";
        teacher.readfrom_esis = true;
        teacher.firstName = teacher.FIRST_NAME
        teacher.lastName = teacher.LAST_NAME
        // batch.set(
        //   school.ref
        //     .collection("teachers")
        //     .doc(teacher.PERSON_ID.toString()),
        //   teacher
        // );
      });
      batch.commit().then(() => {
        school.ref.update({ numberOfTeachers: counter })
        res.data.RESULT.forEach(async (teacher) => {
          var zanInfo = await this.getESIS_ZAN_TEACHER(
            ktoken,
            teacher.PERSON_ID
          );
          if (zanInfo && zanInfo.length > 0) {
            school.ref
              .collection("teachers")
              .doc(teacher.PERSON_ID.toString()).update(zanInfo[0]).then(() => {
                teacher.loading = false;
              });
          }
          teacher.loading = true;
          var teacherLessons = await this.getESIS_ZAN_TEACHER_LESSONS(
            ktoken,
            teacher.PERSON_ID
          );
          if (teacherLessons && teacherLessons.length > 0) {
            teacher["lessonsESIS-" + school.currentYear] =
              teacherLessons;
            school.ref
              .collection("teachers")
              .doc(teacher.PERSON_ID.toString()).update(teacher).then(() => {
                teacher.loading = false;
              });
          }
        })
      });
    } else {
      alert("Мэдээлэл татаxад алдаа гарлаа.");
      this.fb.db.collection("esis-connection-failed").doc({
        createdAt: new Date(),
        accessLink:
          "https://hub.esis.edu.mn/svc/api/hub/teacher/list",
        school: school,
      });
    }
  },

  async getESIS_ZAN(school, fbdb) {
    var res = await this.getESIS_TOKEN(
      school._esisUserName,
      school._esisPword
    );
    // console.log("token read")
    res = await axios
      .post(
        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
        {
          url: "https://hub.esis.edu.mn/svc/api/hub/academic/org",
          token: res.data.token,
        }
      )
    try {
      if (res.status === 200) {
        console.log(res)
        school.ref.update({
          _esis_zan_updatedAt: new Date(),
          _es_zan_read: true
        });
        var batch = fbdb.batch();
        var counter = 0
        res.data.RESULT.forEach((teacher) => {
          counter++
          teacher.accountClosed = false;
          teacher.deleted = false;
          teacher.createdAt = new Date();
          batch.set(
            school.ref
              .collection("zanList")
              .doc(teacher.academicOrganizationId.toString()),
            teacher
          );
        });
        batch.commit().then(() => {
          school.ref.update({ numberOfZan: counter }).then(() => {
            console.log("Done")
          })

        });
      } else {
        alert("Мэдээлэл татаxад алдаа гарлаа.");
        this.fb.db.collection("esis-connection-failed").doc({
          createdAt: new Date(),
          accessLink:
            "https://hub.esis.edu.mn/svc/api/hub/academic/org",
          school: school,
        });
      }
    } catch (err) {
      console.log(err)
    }
  },

  async getESIS_ZAN_TEACHER(ktoken, teacherId) {
    try {
      var res = await axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url: "https://hub.esis.edu.mn/svc/api/hub/teacher/academic/org/" + teacherId,
            token: ktoken,
          }
        )
      console.log(res)
      if (res.status == 200 && res.data) return res.data.RESULT
    } catch (error) {
      console.log("err")
    }
    console.log(res)
    return res
  },
  async getESIS_ZAN_TEACHER_LESSONS(ktoken, teacherId) {
    try {
      var res = await axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url: "https://hub.esis.edu.mn/svc/api/hub/teacher/course/subject/" + teacherId,
            token: ktoken,
          }
        )

      if (res.status == 200 && res.data) return res.data.RESULT
    } catch (error) {
      console.log("https://hub.esis.edu.mn/svc/api/hub/teacher/course/subject/" + teacherId)
    }
    console.log(teacherId)
    return res
  },

  async _loadStudentsOfGroup(progID, ktoken) {
    var xx = await axios
      .post(
        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
        {
          url:
            "https://hub.esis.edu.mn/svc/api/hub/group/student/list/" +
            progID,
          token: ktoken,
        }
      )
    return xx
  },
  async _loadMovements(ddate, ktoken) {
    var xx = await axios
      .post(
        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
        {
          url:
            "https://hub.esis.edu.mn/svc/api/hub/student/movement/"+ ddate,
          token: ktoken,
        }
      )
    return xx
  },
  async getESIS_STUDENT_INFO(ktoken, url, personId, catId) {
    var theUrl = url + "/" + personId + "/" + catId
    try {
      var res = await axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url: theUrl,
            token: ktoken,
          }
        )
      if (res.status == 200 && res.data) return res.data.RESULT
    } catch (error) {
      console.log(theUrl)
    }
    return res
  },
  async getTOPICS_COURSE(ktoken, courseId) {
    try {
      var res = await axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url: "https://hub.esis.edu.mn/svc/api/hub/teacher/topics/courses/"+courseId,
            token: ktoken,
          }
        )
      if (res.status == 200 && res.data) return res.data.RESULT
    } catch (error) {
      console.log(error.message)
    }
    return res
  },
  async writeDailyIrz(ktoken, data) {
    try {
      var res = await axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/writeToESIS",
          {
            url: "https://hub.esis.edu.mn/svc/api/hub/group/school/attendance/save",
            token: ktoken,
            irzData: data
          }
        )
      if (res.status == 200 && res.data) return res.data.RESULT
    } catch (error) {
      console.log("https://hub.esis.edu.mn/svc/api/hub/group/school/attendance/save")
    }
    return res
  },
}
