<template>
  <v-card>
    <div class="px-4 py-4">
      <h6 class="text-h6 text-typo font-weight-bold">
        {{ chartName }} / <span class="font-weight-bolder" style="color:#4E7CFF">{{totalStudents}}</span>
      </h6>
      <div class="card-padding">
        <div class="chart">
          <canvas
            :id="'categoriesChart' + chartName"
            class="chart-canvas"
          ></canvas>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
/*eslint-disable*/
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
Chart.register(ChartDataLabels);
export default {
  components: {},
  data() {
    return {
      chartLabels: null,
      chartNumbers: null,
    };
  },
  computed: {
    ...sync("*"),
    totalStudents() {
      var summe = 0;
      if (this.schools) {
        for (var school of this.schools) {
          if (school.studentNumber12) summe = summe + school.studentNumber12;
        }
      }
      return summe;
    },
  },
  methods: {
    find() {
      // var x = await thissoril.ref
      //   .collection("schools-reports")
      //   .orderBy("averageScore", "desc")
      //   .get();
      // if (!x.empty) {
      //   this.averageScores = [];
      //   this.schoolLabels = [];
      //   this.numberOfParticipants = [];
      //   for (var schoolData of x.docs) {
      //     this.averageScores.push(schoolData.data().averageScore);
      //     this.numberOfParticipants.push(schoolData.data().numberOfStudents);
      //     this.schoolLabels.push(schoolData.data().schoolName);
      //     //this.schoolLabels.push("*****");
      //   }
      // }
      var xChart = document.getElementById("categoriesChart" + this.chartName);
      if (xChart) {
        new Chart(xChart.getContext("2d"), {
          type: "bar",
          data: {
            labels: this.chartLabels,
            datasets: [
              // {
              //   label: "Watts",
              //   tension: 0.4,
              //   borderWidth: 0,
              //   borderRadius: 4,
              //   borderSkipped: false,
              //   backgroundColor: "#3A416F",
              //   data: this.averageScores,
              //   maxBarThickness: 6,
              //   datalabels: {
              //     color: "green",
              //     align: "end",
              //     anchor: "end",
              //   },
              // },
              {
                label: "Сонгосон тоо",
                tension: 0.4,
                borderWidth: 0,
                borderRadius: 4,
                borderSkipped: false,
                backgroundColor: "#4E7CFF",
                data: this.chartNumbers,
                maxBarThickness: 6,
                datalabels: {
                  color: "4E7CFF",
                  align: "end",
                  anchor: "end",
                },
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                color: "#36A2EB",
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#9ca2b7",
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#9ca2b7",
                },
              },
            },
          },
        });
      }
    },
  },
  created() {},
  props: {
    schools: {
      required: true,
      type: Array,
    },
    chartName: {
      required: false,
      type: String,
    },
  },
  mounted() {
    this.chartLabels = this.schools
      ? this.schools.map((el) => el.name.substring(0, 15) + "...")
      : [];
    this.chartNumbers = this.schools
      ? this.schools.map((el) => el.studentNumber12)
      : [];
    this.find();
  },
};
</script>
