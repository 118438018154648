<template>
  <v-card class="z-index-2">
    <div class="px-4 pb-0">
      <!-- <h6 class="text-h6 text-typo font-weight-bold">
        Өвчтэй, чөлөөтэй, тасалсан {{ $attrs.attendanceStatistics }}
      </h6> -->
    </div>
    <div class="pt-0">
      <div class="chart">
        <canvas
          :id="$attrs.chartId"
          class="chart-canvas"
          :key="counter"
        ></canvas>
      </div>
    </div>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  name: "bar-chart-horizontal",
  data: function () {
    return {
      barChartHorizontalId: "bar-chart-horizontal",
      dataas: null,
      counter: 0,
      ccc: null,
    };
  },
  watch: {
    $attrs: {
      handler(val) {
        // this.$forceUpdate();
        if (val.attendanceStatistics) {
          this.dataas[0].data = [
            val.attendanceStatistics["studentsUnknown"]?val.attendanceStatistics["studentsUnknown"]:0,
            val.attendanceStatistics["studentsLate"]?val.attendanceStatistics["studentsLate"]:0,
            val.attendanceStatistics["studentsExcused"]?val.attendanceStatistics["studentsExcused"]:0,
            val.attendanceStatistics["studentsSick"]?val.attendanceStatistics["studentsSick"]:0,
            val.attendanceStatistics["studentsAbsent"]?val.attendanceStatistics["studentsAbsent"]:0,
            val.attendanceStatistics["studentsPresent"]?val.attendanceStatistics["studentsPresent"]:0,
          ];
          if (this.ccc) this.ccc.update();
        }
      },
      deep: true,
    },
  },
  created() {
    this.dataas = [
      {
        label: "",
        weight: 2,
        borderWidth: 0,
        borderRadius: 4,
        backgroundColor: ["#bbb","#bbb", "#87B3FC", "#FFA500", "#FFFB00", "#cb0c9f"],
        data: [
          this.$attrs.attendanceStatistics
            ? this.$attrs.attendanceStatistics["studentsUnknown"]
            : 0,
          this.$attrs.attendanceStatistics
            ? this.$attrs.attendanceStatistics["studentsLate"]
            : 0,
          this.$attrs.attendanceStatistics
            ? this.$attrs.attendanceStatistics["studentsExcused"]
            : 0,
          this.$attrs.attendanceStatistics
            ? this.$attrs.attendanceStatistics["studentsSick"]
            : 0,
          this.$attrs.attendanceStatistics
            ? this.$attrs.attendanceStatistics["studentsAbsent"]
            : 0,
          this.$attrs.attendanceStatistics
            ? this.$attrs.attendanceStatistics["studentsPresent"]
            : 0,
        ],
        fill: false,
      },
    ];
  },
  mounted() {
    var chartDiv = document.getElementById(this.$attrs.chartId);
    if (chartDiv) {
      this.ccc = new Chart(chartDiv.getContext("2d"), {
        type: "bar",
        data: {
          labels: [
            "Тодорxойгүй",
            "Хоцорсон",
            "Чөлөөтэй",
            "Өвчтэй",
            "Тасалсан",
            "Ирсэн",
          ],
          datasets: this.dataas,
        },
        options: {
          indexAxis: "y",
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 4,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: true,
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 1,
              },
            },
          },
        },
      });
    }
  },
};
</script>
