<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col md="8" class="me-auto text-start">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">Дүн шилжилгээ</h5>
        <p class="text-sm text-body mb-0">
          Xяналтын самбарын тусламжтай өгөгдөл шинжилгээг xийж сургуулийн үйл
          ажиллагаагаа xянаx боломжтой.
          <span v-if="lessonCategories">{{ lessonCategories.length }}</span>
        </p>
      </v-col>
    </v-row>
    <!-- <v-btn
      class="my-5 white--text"
      color="red"
      v-if="this.userData.role == 'superadmin'"
      @click="resetNumbers()"
      >Reset Student and Teacher numbers</v-btn
    > -->
    <v-row>
      <v-col lg="4" md="12" sm="12">
        <div class="chart">
          <NumberOfTeacherAndStudents
            v-if="loading == false"
            :totalStudents="totalStudents"
            :totalTeachers="totalTeachers"
            :schools="schools"
          ></NumberOfTeacherAndStudents>
          <v-row class="ma-0" v-else>
            <v-col md="6" cols="12">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="text-gradient text-success font-weight-bolder text-h1"
                  >
                    <v-progress-circular
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                    Нийт Сурагч
                  </h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
            <v-col md="6" cols="12">
              <v-card class="border-radius-xl card-shadow">
                <div class="px-6 py-6 text-center">
                  <h1
                    class="text-gradient text-success font-weight-bolder text-h1"
                  >
                    <v-progress-circular
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </h1>
                  <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                    Нийт Багш
                  </h6>
                  <p class="opacity-8 mb-0 text-sm text-typo"></p>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div class="chart mt-6" v-if="userData.role != 'aspecialist'">
          <DepartmentGroupsChart

            v-if="loading == false"
          ></DepartmentGroupsChart>

          <v-card v-else>
            <div class="px-4 py-4">
              <h6 class="text-h6 text-typo font-weight-bold">
                Сурагчдын тоо (ангиар)
              </h6>

              <div class="card-padding text-center">
                <v-progress-circular
                  indeterminate
                  color="green"
                ></v-progress-circular>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col lg="5" md="12" sm="12">
        <v-card class="card-shadow border-radius-xl">
          <div class="px-4 py-4">
            <div class="chart">
              <OrolzooChart
                v-if="lessonCategories && lessonCategories.length >= 10"
                :lessonCategories="lessonCategories"
                :lessonCategoriesLabels="lessonCategoriesLabels"
                v-bind="$attrs"
              ></OrolzooChart>
              <h2 v-else class="gray--text text-center">
                ЭЕШ шалгалт өгөx сурагчдын мэдээлэл
              </h2>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col lg="3" md="3" sm="12">
        <v-card class="card-shadow border-radius-xl" @click="showBasicAlert">
          <div class="px-4 py-4">
            <div class="chart">
              <GenderPieChart></GenderPieChart>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <div class="chart">
          <div v-if="loading == false">
            <ClassGroupsChart
              v-if="
                classGroup &&
                classGroup.find((group) => group.departmentName == '1')
              "
              :classGroup="classGroup"
            ></ClassGroupsChart>
          </div>

          <v-card v-else>
            <div class="px-4 py-4">
              <h6 class="text-h6 text-typo font-weight-bold">
                Сурагчдын тоо (бүлгээр)
              </h6>

              <div class="card-padding text-center">
                <v-progress-circular
                  indeterminate
                  color="green"
                ></v-progress-circular>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="
        isEYESH_APP &&
        userData &&
        (userData.role == 'aspecialist' || userData.role == 'superadmin')
      "
    >
      <v-col cols="12">
        <v-card class="card-shadow border-radius-xl">
          <div class="px-4 py-4">
            <div class="chart">
              <BarXYChart
                v-if="schools"
                :schools="schools"
                :chartName="'Төгсөгчдийн тоо (сургууль бүрээр)'"
              ></BarXYChart>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12">
        <v-card class="card-shadow border-radius-xl" @click="showBasicAlert">
          <div class="px-4 py-4">
            <div class="chart">
              <NumberOfStudents></NumberOfStudents>
            </div>
          </div>
        </v-card>

        <v-card
          class="card-shadow border-radius-xl my-4"
          :key="sorilIndex"
          v-for="(soril, sorilIndex) in sorils"
        >
          <div class="px-4 py-4">
            <div class="chart">
              <SchoolChart :soril="soril" v-bind="$attrs"></SchoolChart>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>
<script>
import Chart from "chart.js/auto";
import OrolzooChart from "@/views/Charts/OrolzooChart";
import BarXYChart from "@/views/Charts/BarXYChart";

// import SorilsPerSchools from "@/views/Charts/SorilsPerSchools";
// import RadarChart from "@/views/Charts/RadarChart.vue";
import GenderPieChart from "@/views/Charts/GenderPieChart.vue";
import NumberOfTeacherAndStudents from "@/views/Charts/NumberOfTeacherAndStudents.vue";
import DepartmentGroupsChart from "@/views/Charts/DepartmentGroupsChart.vue";
import ClassGroupsChart from "@/views/Charts/ClassGroupsChart.vue";
// import LessonChart from "@/views/Charts/LessonChart.vue";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");

export default {
  name: "Projects",
  data: function () {
    return {
      lessonCategoriesLabels: null,
      totalTeachers: null,
      totalStudents: null,
      schools: null,
      sorils: null,
      chartLine1: "chart-line-1",
      school: null,
      lessonCategories: null,
      classGroup: null,
      lessons: null,
      loading: true,
    };
  },
  components: {
    // SorilsPerSchools,
    GenderPieChart,
    // RadarChart,
    // LessonChart,
    NumberOfTeacherAndStudents,
    OrolzooChart,
    DepartmentGroupsChart,
    ClassGroupsChart,
    BarXYChart,
  },
  computed: {
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
    isEYESH_APP() {
      return fb.EYESH_APP;
    },
    xDataLessonNumberOfStudents() {
      return this.lessonCategories.map((element) => element.name);
    },
  },
  created() {
    if (this.userData.school) {
      console.log(this.userData.school);

      if (this.userData.school.teachersNumber) {
        this.totalTeachers = this.userData.school.teachersNumber;
      } else {
        this.userData.school.ref
          .collection("teachers")
          .where("deleted", "==", false)
          .onSnapshot((teachers) => {
            this.totalTeachers = teachers.size;
            this.userData.school.ref.update({ teachersNumber: teachers.size });
          });
      }
      if (this.userData.school.studentsNumber) {
        this.totalStudents = this.userData.school.studentsNumber;
      } else {
        this.userData.school.ref
          .collection("students")
          .where("deleted", "==", false)
          .onSnapshot((students) => {
            this.totalStudents = students.size;
            this.userData.school.ref.update({ studentsNumber: students.size });
          });
      }
      
      fb.db
        .collection("categories")
        // .orderBy("counter", "desc") askKhangal
        .onSnapshot((docs) => {
          this.lessonCategories = [];
          this.lessonCategoriesLabels = [];
          docs.forEach(async (doc) => {
            let lcat = doc.data();
            lcat.id = doc.id;
            lcat.ref = doc.ref;
            console.log(lcat.ref.path)
            lcat.ref
              .collection("lesson-students")
              .where("ppschool", "==", this.userData.ppschool)
              .onSnapshot((xx) => {
                if (xx.empty) lcat.counter = 0;
                else lcat.counter = xx.size;
                this.lessonCategories.push(lcat);
                this.lessonCategoriesLabels.push(lcat.name);
              });
          });
        });
    } else {
      fb.db
        .collection("categories")
        .orderBy("counter", "desc")
        .onSnapshot((docs) => {
          this.lessonCategories = [];
          docs.forEach((doc) => {
            let lcat = doc.data();
            lcat.id = doc.id;
            lcat.ref = doc.ref;

            if (
              this.userData.role == "aspecialist" &&
              this.userData.schoolIndex
            ) {
              lcat.counter = lcat["counter-" + this.userData.schoolIndex];
            }
            this.lessonCategories.push(lcat);
          });
        });
      var query;
      if (this.userData.role == "aspecialist" && this.userData.schoolIndex) {
        query = fb.db
          .collection("schools")
          .where("deleted", "==", false)
          .where("schoolIndex", "==", this.userData.schoolIndex);
      } else {
        query = fb.db.collection("schools").where("deleted", "==", false);
      }

      query.onSnapshot((querySnapshot) => {
        this.schools = [];
        var index = 0;
        this.totalStudents = 0;
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          item.studentsNumber2 = null;
          item.teachersNumber2 = null;
          item.index = ++index;

          this.schools.push(item);

          if (
            this.userData.role == "aspecialist" &&
            this.userData.schoolIndex
          ) {
            this.totalTeachers = 11;
            fb.db
              .collectionGroup("students")
              .where("deleted", "==", false)
              .where("schoolIndex", "==", this.userData.schoolIndex)
              .onSnapshot((students) => {
                this.totalStudents = students.size;
              });

            fb.db
              .collectionGroup("teachers")
              .where("deleted", "==", false)
              .where("schoolIndex", "==", this.userData.schoolIndex)
              .onSnapshot((teachers) => {
                this.totalTeachers = teachers.size;
              });
          } else {
            if (this.userData.school) {
              this.userData.school.ref
                .collection("teachers")
                .where("deleted", "==", false)
                .onSnapshot((teachers) => {
                  this.totalTeachers = teachers.size;
                });
              this.userData.school.ref
                .collection("students")
                .where("deleted", "==", false)
                .onSnapshot((students) => {
                  this.totalStudents = students.size;
                });
            } else {
              fb.db.collectionGroup("teachers").onSnapshot((teachers) => {
                this.totalTeachers = teachers.size;
              });
              fb.db.collectionGroup("students").onSnapshot((students) => {
                this.totalStudents = students.size;
              });
            }
          }
        });
      });
    }

    if (this.zzschool) {
      fb.db.doc(this.zzschool).onSnapshot((doc) => {
        let school = doc.data();
        school.id = doc.id;
        school.ref = doc.ref;
        this.school = school;
      });
    }
    fb.db
      .collection("assignmentsdatabase")
      .where("deleted", "==", false)
      .where("finished", "==", true)
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        this.sorils = [];
        docs.forEach((doc) => {
          let assignment = doc.data();
          assignment.id = doc.id;
          assignment.ref = doc.ref;
          this.sorils.push(assignment);
        });
        setTimeout(() => {
          this.loading = false;
          console.log(this.loading);
        }, 1000);
      });

    if (this.userData.school) {
      this.userData.school.ref
        .collection("lessons")
        .get()
        .then((docs) => {
          this.lessons = [];
          if (!docs.empty) {
            docs.forEach(async (lesson) => {
              let les = lesson.data();
              les.studentSize = 0;

              if (les.classGroupRefs) {
                for (var classRef of les.classGroupRefs) {
                  await this.userData.school.ref
                    .collection("students")
                    .where(
                      "classGroup-" + this.userData.school.currentYear,
                      "==",
                      classRef
                    )
                    .get()
                    .then((students) => {
                      les.studentSize = les.studentSize + students.size;
                    });
                }
              }

              this.lessons.push(les);
            });
          }
        });
      this.userData.school.ref
        .collection("departments")
        .where("startYear", "==", 2022)//YTODO
        .orderBy("index", "desc")
        .onSnapshot(async (querySnapshot) => {
          this.classGroup = [];
          await querySnapshot.forEach((xdoc) => {
            xdoc.ref
              .collection("programs")
              .orderBy("name", "asc")
              .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let prog = doc.data();
                  prog.departmentName = xdoc.data().name;
                  prog.name = xdoc.data().name + prog.name.toUpperCase();
                  if (!prog.numberOfStudents)
                    this.userData.school.ref
                      .collection("students")
                      .where(
                        "classGroup-" + this.userData.school.currentYear,
                        "==",
                        doc.ref
                      )
                      .get()
                      .then((student) => {
                        doc.ref.update({ numberOfStudents: student.size });
                        prog.numberOfStudents = student.size;
                      });
                  this.classGroup.push(prog);
                });
              });
          });
        });
    }
  },
  methods: {
    resetNumbers() {
      fb.db
        .collection("schools")
        .get()
        .then((docs) => {
          docs.forEach(async (doc) => {
            let school = doc.data();
            school.ref = doc.ref;
            school.id = doc.id;

            // await school.ref
            //   .collection("teachers")
            //   .where("deleted", "==", false)
            //   .get()
            //   .then((docs) => {
            //     console.log(docs.size)
            //     school.ref.update({
            //       teachersNumber: docs.size,
            //     });
            //   });
            // await school.ref
            //   .collection("students")
            //   .where("deleted", "==", false)
            //   .get()
            //   .then((docs) => {
            //     console.log(docs.size)
            //     school.ref.update({
            //       studentsNumber: docs.size,
            //     });
            //   });

            // await school.ref
            //   .collection("departments")
            //   .get()
            //   .then((docs) => {
            //     docs.forEach((doc) => {
            //       let department = doc.data();
            //       department.ref = doc.ref;
            //       department.id = doc.id;

            //       school.ref
            //         .collection("students")
            //         .where(
            //           "department-" + school.currentYear,
            //           "==",
            //           department.ref
            //         )
            //         .where("deleted", "==", false)
            //         .get()
            //         .then((docs) => {
            //           department.ref.update({ numberOfStudents: docs.size });
            //         });

            //       department.ref
            //         .collection("programs")
            //         .get()
            //         .then((docs) => {
            //           docs.forEach((doc) => {
            //             let program = doc.data();
            //             program.id = doc.id;
            //             program.ref = doc.ref;

            //             school.ref
            //               .collection("students")
            //               .where(
            //                 "classGroup-" + school.currentYear,
            //                 "==",
            //                 program.ref
            //               )
            //               .where("deleted", "==", false)
            //               .get()
            //               .then((docs) => {
            //                 program.ref.update({ numberOfStudents: docs.size });
            //               });
            //           });
            //         });
            //     });
            //   });
            console.log(school.name);
          });
          console.log("done");
        });
    },
    showBasicAlert() {
      //this.$swal("Таны ашиглаж байгаа xувилбар туршилтын xувилбар байна. ");
    },
    collectStudents(student) {
      for (var school of this.schools) {
        if (school.ref.path == student.ref.parent.parent.path) {
          if (school.students == null) school.students = [];
          school.students.push(student);
        }
      }
    },
  },
  mounted() {
    var element = document.getElementById(this.chartLine1);
    if (element) {
      var chart1 = document.getElementById(this.chartLine1).getContext("2d");
      var gradientStroke1 = chart1.createLinearGradient(0, 230, 0, 50);
      gradientStroke1.addColorStop(1, "rgba(203,12,159,0.02)");
      gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke1.addColorStop(0, "rgba(203,12,159,0)"); //purple colors
      new Chart(chart1, {
        type: "line",
        data: {
          labels: ["9", "10", "11", "12", "1", "2", "3", "4", "5"],
          datasets: [
            {
              label: "Visitors",
              tension: 0.5,
              pointRadius: 0,
              borderColor: "#cb0c9f",
              borderWidth: 2,
              backgroundColor: gradientStroke1,
              data: [50, 45, 60, 60, 80, 65, 45, 60, 60],
              maxBarThickness: 6,
              fill: true,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
          },
        },
      });
    }
  },
};
</script>
